import {
    type HTMLAttributes,
    type CSSProperties,
    type ComponentPropsWithRef,
    type JSX,
} from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { ButtonLink } from "@/Components/Link";
import { SpinWheel } from "@/Components/SpinWheel";
import { type Props } from "@/Components/SpinWheel/StaticWheel";
import { Text } from "@/Components/Text";

const textStyle: CSSProperties = {
    color: "#283A5B",
    fontFamily: "Inter, sans-serif",
    fontSize: "9px",
    fontWeight: "500",
};

export const slices: Props["slices"] = [
    {
        backgroundColor: "#FECF50",
        text: "Page not found",
        textStyle,
    },
    {
        backgroundColor: "#53E1EA",
        text: "Unauthorized",
        textStyle,
    },
    {
        backgroundColor: "#A398FE",
        text: "Server error",
        textStyle,
    },
    {
        backgroundColor: "#53E1EA",
        text: "Forbidden",
        textStyle,
    },
    {
        backgroundColor: "#FECF50",
        text: "Page not found",
        textStyle,
    },
    {
        backgroundColor: "#A398FE",
        text: "Server error",
        textStyle,
    },
    {
        backgroundColor: "#FECF50",
        text: "Forbidden",
        textStyle,
    },
    {
        backgroundColor: "#53E1EA",
        text: "Unauthorized",
        textStyle,
    },
    {
        backgroundColor: "#A398FE",
        text: "Server error",
        textStyle,
    },
    {
        backgroundColor: "#53E1EA",
        text: "Forbidden",
        textStyle,
    },
    {
        backgroundColor: "#A398FE",
        text: "Server error",
        textStyle,
    },
    {
        backgroundColor: "#53E1EA",
        text: "Unauthorized",
        textStyle,
    },
];

const WheelContainer = styled.div`
    height: 300px;
    width: 300px;
`;

export const Title = styled(
    ({
        children,
        value,
        ...otherProps
    }: Omit<HTMLAttributes<HTMLDivElement>, "children"> & {
        children: string;
        value: string;
    }): JSX.Element => {
        const sliceIndex = slices.findIndex((s) => s.text === value);

        // A slice is 30deg. -105 is because 0 is top but caret is at -90 (-15 for center)
        const deg = sliceIndex === -1 ? 0 : sliceIndex * 30 - 105;

        return (
            <div {...otherProps}>
                {children[1] === "0" ? (
                    <>
                        {children[0]}
                        <WheelContainer>
                            <SpinWheel
                                deg={deg}
                                flapperColor="var(--color-blue-300)"
                                slices={slices}
                            />
                        </WheelContainer>
                        {children[2]}
                    </>
                ) : (
                    children
                )}
            </div>
        );
    },
)`
    align-items: center;
    color: var(--color-neutral-300);
    display: flex;
    font-size: 272px;
    font-weight: 900;
    gap: 26px;
    margin-bottom: 20px;
`;

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    justify-content: center;
    padding: 20px;
`;

export const SubTitle = styled(Text.BodyL)`
    color: var(--color-neutral-700);
    font-weight: 500;
`;

export const Description = styled(Text.Body)`
    color: var(--color-neutral-500);
    max-width: 360px;
    text-align: center;
`;

export const Actions = styled.div`
    display: flex;
    gap: 6px;
    margin-top: 12px;
`;

export const BackToHome = (
    props: Partial<ComponentPropsWithRef<typeof ButtonLink>>,
): JSX.Element => {
    const intl = useIntl();

    return (
        <ButtonLink kind="primary" size="small" to="/account" {...props}>
            {intl.formatMessage({
                id: "error.common.action.back",
                defaultMessage: "Back to home",
                description: "Generic error page back button text",
            })}
        </ButtonLink>
    );
};
