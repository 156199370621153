import { loadPreferences, onPreferencesSaved } from "@segment/consent-manager";
import {
    type AnchorHTMLAttributes,
    type JSX,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { defaultFocusOutlineMixin } from "@/Support/defaultFocusOutlineMixin";

export const HelpTrigger = styled(
    (
        props: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href" | "id">,
    ): JSX.Element => {
        const intl = useIntl();

        const [href, setHref] = useState("https://support.wisepops.com/");

        const setConfirmConsent = useCallback(() => {
            const preferences = loadPreferences();

            if (preferences.customPreferences?.functional) {
                setHref("https://support.wisepops.com/");
            } else {
                setHref("https://support.wisepops.com/#confirm-consent");
            }
        }, []);

        useEffect(() => {
            setConfirmConsent();
            onPreferencesSaved(setConfirmConsent);
        }, [setConfirmConsent]);

        return (
            <a
                {...props}
                className={`m-intercom ${props.className ?? ""}`}
                href={href}
                id="wisepops_support"
            >
                <svg
                    fill="none"
                    height="15"
                    width="18"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        clipRule="evenodd"
                        d="M5.002.553a50.577 50.577 0 018.099.04l1.623.138a2.666 2.666 0 012.409 2.252l.102.669a20.664 20.664 0 01-.096 6.835 2.343 2.343 0 01-2.305 1.923H5.858c-.207 0-.41.051-.592.149L1.355 14.66A.75.75 0 01.25 14V5.483A4.93 4.93 0 014.809.567l.193-.014zM5 5.5A1.25 1.25 0 105 8a1.25 1.25 0 000-2.5zm4 0A1.25 1.25 0 109 8a1.25 1.25 0 000-2.5zm2.75 1.25a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
                        fill="currentColor"
                        fillRule="evenodd"
                    />
                </svg>
                {intl.formatMessage({
                    id: "components.helptrigger.content",
                    description: "Help trigger button component text content",
                    defaultMessage: "Help",
                })}
            </a>
        );
    },
)`
    &,
    &:hover,
    &:visited,
    &:active {
        background: #1b2722;
        border-radius: 24px;
        bottom: 20px;
        box-shadow:
            0 2px 4px rgb(27 39 51 / 4%),
            0 8px 12px rgb(27 39 51 / 8%);
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        padding: 8px 12px;
        position: fixed;
        right: 20px;
        text-decoration: none;
        z-index: var(--z-index-help);
    }

    & svg {
        display: inline-block;
        margin-right: 4px;
        margin-top: -1px;
        vertical-align: middle;
    }

    &:not(:hover) svg {
        opacity: 0.9;
    }

    ${defaultFocusOutlineMixin}
`;
