import { useRef, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useCurrentWorkspace } from "@/Support/Hooks/useCurrentWorkspace";
import { useSegment } from "@/Support/Hooks/useSegment";
import { useBilling } from "@/Support/Requests/useBilling";
import { useEditorBillingPageviews } from "@/Support/Requests/useEditorBillingPageviews";
import { useMe } from "@/Support/Requests/useMe";

import { BillingIntervalEnum } from "@/Pages/Billing/types";
import { UserRole } from "@/Pages/types";

export const useWisepops = (): void => {
    const location = useLocation();

    const segment = useSegment();

    const [searchParams, setSearchParams] = useSearchParams();

    const wisepopsInitRef = useRef(false);

    const currentWorkspace = useCurrentWorkspace();

    const isEditor = currentWorkspace?.role === UserRole.EDITOR;

    const { isSuccess: isEditorBillingSuccess, data: editorBillingData } =
        useEditorBillingPageviews(
            { workspaceId: currentWorkspace?.workspace.id ?? 0 },
            {
                enabled:
                    typeof currentWorkspace?.workspace.id === "number" &&
                    isEditor,
            },
        );

    const { isSuccess: isAdminBillingSuccess, data: adminBillingData } =
        useBilling(
            { workspace: currentWorkspace?.workspace.id ?? 0 },
            {
                enabled:
                    typeof currentWorkspace?.workspace.id === "number" &&
                    !isEditor,
            },
        );

    const {
        isError: isMeError,
        isSuccess: isMeSuccess,
        data: meData,
    } = useMe();

    useEffect(
        () => {
            if (isMeError) {
                if (!wisepopsInitRef.current) {
                    wisepopsInitRef.current = true;
                    window.initWisp?.();
                }
                if (
                    searchParams.get("after_logout") === "true" &&
                    location.pathname === "/login"
                ) {
                    segment.reset();
                    setSearchParams(
                        (prev) => {
                            prev.delete("after_logout");
                            return prev;
                        },
                        { replace: true },
                    );
                }
                return;
            }
            if (wisepopsInitRef.current) return;
            if (
                isMeSuccess &&
                ((!isEditor && isAdminBillingSuccess) ||
                    (isEditor && isEditorBillingSuccess))
            ) {
                const planPriceMonth =
                    adminBillingData?.billingInterval ===
                    BillingIntervalEnum.YEARLY
                        ? adminBillingData.planPrice / 12
                        : adminBillingData?.planPrice;
                const [item] = meData.workspaces.items; // TODO: later improve when handling multiple workspaces

                if (item === undefined) return;

                wisepopsInitRef.current = true;
                segment.identify(meData);
                window.initWisp?.({
                    planPriceMonth,
                    custom: {
                        userRole: item.role,
                    },
                });
                window.wisepops?.(
                    "properties",
                    {
                        user: {
                            w2Id: meData.id,
                            firstName: meData.name,
                            role: item.role,
                            creationDate: meData.created_at,
                        },
                        company: {
                            canaryReleases: item.workspace.releases.join(","),
                            planPriceMonth,
                            accountEndDate:
                                editorBillingData?.expires_at ??
                                adminBillingData?.expiresAt,
                        },
                        workspace: {
                            w2Id: item.workspace.id,
                            name: item.workspace.company_name,
                            creationDate: item.workspace.created_at,
                            popupsAndNotifs: [
                                8227, 9811, 30_368, 30_486, 30_994, 32_896,
                                35_937, 36_569, 36_746, 36_985, 37_115, 37_257,
                                37_303, 38_469, 40_768, 40_986, 41_182, 41_195,
                                41_366, 41_425, 41_469, 41_485, 41_632, 41_666,
                                42_032, 42_069, 42_209, 42_383, 42_638, 42_716,
                                42_992, 43_012, 43_039, 43_276, 43_420, 44_735,
                                45_167, 45_224, 45_594, 46_205, 46_861, 46_984,
                                47_151, 47_155, 47_533, 47_608, 47_764, 47_906,
                                48_296, 48_537, 48_773, 49_157, 49_408, 49_653,
                                49_877, 49_937, 49_955, 50_190, 50_255, 50_742,
                                50_911, 51_364, 51_442, 51_549, 51_649, 52_073,
                                52_087, 52_099, 52_218, 52_306, 52_988, 53_019,
                                53_165, 53_372, 53_453, 53_545, 53_560, 53_772,
                                53_898, 53_927, 54_151, 54_317, 54_503, 54_568,
                                54_678, 54_796, 54_841, 55_070, 55_194, 55_373,
                                55_463, 55_538, 55_617, 55_624, 55_639, 55_791,
                                55_793, 55_866, 55_927, 56_003, 56_050, 56_106,
                                56_139, 56_199, 56_322, 56_423, 56_457, 56_539,
                                56_879, 56_908, 56_927, 56_974, 57_260, 57_267,
                                57_643, 57_753, 57_844, 57_968, 58_116, 58_847,
                                58_879, 59_035, 59_051, 59_162, 59_375, 59_484,
                                59_544, 59_669, 59_827, 59_988, 60_003, 60_058,
                                60_080, 60_091, 60_114, 60_128, 60_226, 60_270,
                                60_296, 60_344, 60_386, 60_429, 60_470, 60_684,
                                60_903, 60_904, 60_932, 61_132, 61_211, 61_221,
                                61_226, 61_246, 61_307, 61_435, 61_643, 61_766,
                                61_815, 61_843, 61_869, 61_957, 62_009, 62_048,
                                62_252, 62_584, 62_607, 62_632, 62_791, 62_801,
                                62_823, 62_935, 63_014, 63_030, 63_137, 63_195,
                                63_402, 63_507, 63_515, 63_578, 63_599, 63_634,
                                63_687, 63_704, 63_755, 63_874, 63_977, 64_055,
                                64_145, 64_245, 64_317, 64_358, 64_367, 64_426,
                                64_856, 65_034, 65_077, 65_103, 65_139, 65_243,
                                65_261, 65_316, 31_539_137, 31_539_309,
                                31_539_419, 31_539_452, 31_539_503, 31_539_880,
                                31_540_094, 31_540_166, 31_540_778, 31_540_804,
                                31_540_922, 31_541_170, 31_541_175, 31_541_215,
                                31_541_850, 31_542_193, 31_542_402, 31_542_433,
                                31_542_931, 31_543_500, 31_543_602, 31_543_871,
                                31_544_027, 31_544_033, 31_544_151, 31_544_228,
                                31_544_269, 31_544_273, 31_544_334, 31_544_461,
                                31_544_477,
                            ].includes(item.workspace.id),
                        },
                    },
                    { temporary: true },
                );
            }
        },
        /* eslint-disable-line react-hooks/exhaustive-deps */ [
            isEditorBillingSuccess,
            isAdminBillingSuccess,
            isMeSuccess,
            isMeError,
        ],
    );
};
