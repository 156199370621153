import { useMemo } from "react";
import { type IntlShape, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { type ToastKind } from "@/Components/Toast";

const getSharedMessages = (intl: IntlShape) => {
    return {
        "plan.updated": intl.formatMessage({
            id: "pages.plan.updated",
            description: "Toast pop-in message after plan update success",
            defaultMessage: "Plan updated successfully",
        }),
        "plan.error": intl.formatMessage({
            id: "pages.plan.error",
            description: "Toast error after plan update failure",
            defaultMessage:
                "Could not update plan. Please try again or contact support",
        }),
    } as const;
};

// TODO: rework to descriptors dictionary when intl extract supports non-inlined descriptors
export const getQueryParamsToastMessages = (
    intl: IntlShape,
): Record<string, string> => {
    const sharedMessages = getSharedMessages(intl);

    return {
        "users.delete.success": intl.formatMessage({
            id: "users.delete.success.toast",
            description: "Toast message when a user is deleted",
            defaultMessage: "Success",
        }),
        "users.invite.success": intl.formatMessage({
            id: "users.invite.success.toast",
            description: "Toast message when a user is invited",
            defaultMessage: "Success",
        }),
        "websites.create.success": intl.formatMessage({
            id: "websites.create.success.toast",
            description: "Toast message when a website is created",
            defaultMessage: "Success",
        }),
        "websites.delete.success": intl.formatMessage({
            id: "websites.delete.success.toast",
            description: "Toast message when a website is deleted",
            defaultMessage: "Success",
        }),
        "websites.update.success": intl.formatMessage({
            id: "websites.update.success.toast",
            description: "Toast message when a website is updated",
            defaultMessage: "Success",
        }),
        "billing.unsubscribe.success": intl.formatMessage({
            id: "billing.unsubscribe.success.toast",
            description:
                "Toast message when a client unsubscribed from invoicing emails",
            defaultMessage: "Success",
        }),
        "billing.unsubscribe.error": intl.formatMessage({
            id: "billing.unsubscribe.error.toast",
            description:
                "Toast message when unsubscribing from invoicing email fail",
            defaultMessage:
                "Something went wrong. Please try again or contact support",
        }),
        "billing.websites.count.error": intl.formatMessage({
            id: "billing.websites.count.error.toast",
            description:
                "Toast message when updating billed website count fail",
            defaultMessage: "Error",
        }),
        "billing.users.count.error": intl.formatMessage({
            id: "billing.users.count.error.toast",
            description: "Toast message when updating billed user count fail",
            defaultMessage: "Error",
        }),
        "popups.shopify.install.already_exists": intl.formatMessage({
            id: "popups.shopify.install.already_exists",
            description:
                "Toast message when a user try to install popups on an already installed Shopify store",
            defaultMessage:
                "Popups are already installed on this shop, it can't be installed again",
        }),
        "notifications.shopify.install.already_exists": intl.formatMessage({
            id: "notifications.shopify.install.already_exists",
            description:
                "Toast message when a user try to install notifications on an already installed Shopify store",
            defaultMessage:
                "Notifications are already installed on this shop, it can't be installed again",
        }),
        "billing.shopify.success": sharedMessages["plan.updated"],
        "billing.stripe.success": sharedMessages["plan.updated"],
        "billing.shopify.error": sharedMessages["plan.error"],
        "billing.stripe.error": sharedMessages["plan.error"],
    };
};

export const getQueryParamsToastLevel: Record<
    keyof typeof getQueryParamsToastMessages,
    ToastKind
> = {
    "users.delete.success": "default",
    "users.invite.success": "default",
    "billing.shopify.success": "default",
    "billing.stripe.success": "default",
    "websites.create.success": "default",
    "websites.delete.success": "default",
    "websites.update.success": "default",
    "billing.unsubscribe.success": "default",
    "billing.websites.count.error": "error",
    "billing.users.count.error": "error",
    "billing.shopify.error": "error",
    "billing.stripe.error": "error",
    "billing.unsubscribe.error": "error",
    "popups.shopify.install.already_exists": "error",
    "notifications.shopify.install.already_exists": "error",
};

export const useQueryParamsToast = ():
    | {
          message: string;
          level: ToastKind;
      }
    | undefined => {
    const intl = useIntl();

    const [searchParams] = useSearchParams();

    const key = searchParams.get("toast") ?? "";

    const messages = useMemo(() => getQueryParamsToastMessages(intl), [intl]);

    return useMemo(() => {
        if (!key) {
            return;
        }

        const message = messages[key];

        if (message === undefined) {
            return;
        }

        return {
            level: getQueryParamsToastLevel[
                key as keyof typeof getQueryParamsToastMessages
            ],
            message,
        };
    }, [key, messages]);
};
