/* eslint-disable unicorn/filename-case */
import { type GraphQLError, type GraphQLErrorExtensions } from "graphql";
import { type Variables, GraphQLClient } from "graphql-request";

/**
 * GraphQL queries/mutations operations names
 * @deprecated
 * When reworking operation to codegen, please remove them from here
 */
export enum GraphQLQuery {
    MeQuery = "MeQuery",
    AlertsQuery = "AlertsQuery",
    WorkspaceQuery = "WorkspaceQuery",
    WorkspaceGoalsSettingsQuery = "WorkspaceGoalsSettingsQuery",
    UserQuery = "UserQuery",
    UserBasicsQuery = "UserBasicsQuery",
    UserWebsitesQuery = "UserWebsitesQuery",
    UsersQuery = "UsersQuery",
    UsersBasicsQuery = "UsersBasicsQuery",
    WebsitesQuery = "WebsitesQuery",
    WebsitesCountQuery = "WebsitesCountQuery",
    WebsitesBasicsQuery = "WebsitesBasicsQuery",
    WebsiteQuery = "WebsiteQuery",
    WebsiteBasicsQuery = "WebsiteBasicsQuery",
    WebsiteEditorsQuery = "WebsiteEditorsQuery",
    WebsiteWispQuery = "WebsiteWispQuery",
    WebsiteNotificationSettingsQuery = "WebsiteNotificationSettingsQuery",
    WebsiteNotificationSettingsMutation = "WebsiteNotificationSettingsMutation",
    WebsiteNotificationSettingsBellUploadMutation = "WebsiteNotificationSettingsBellUploadMutation",
    WebsiteWisepopsQuery = "WebsiteWisepopsQuery",
    WebsiteInstallationQuery = "WebsiteInstallationQuery",
    WebsiteAnalyticsQuery = "WebsiteAnalyticsQuery",
    WebsiteAnalyticsSummaryQuery = "WebsiteAnalyticsSummaryQuery",
    WebsiteAnalyticsFilterAutocompleteQuery = "WebsiteAnalyticsFilterAutocompleteQuery",
    WebsiteAnalyticsCampaignsCountQuery = "WebsiteAnalyticsCampaignsCountQuery",
    WebsiteAnalyticsCampaignsQuery = "WebsiteAnalyticsCampaignsQuery",
    WebsiteAnalyticsCampaignsBasicsQuery = "WebsiteAnalyticsCampaignsBasicsQuery",
    WebsiteAnalyticsCampaignsExportMutation = "WebsiteAnalyticsCampaignsExportMutation",
    WebsiteTopMetricsQuery = "WebsiteTopMetricsQuery",
    WebsiteTopCampaignsQuery = "WebsiteTopCampaignsQuery",
    WebsiteShopifyQuery = "WebsiteShopifyQuery",
    BillingQuery = "BillingQuery",
    BillingExtendedQuery = "BillingExtendedQuery",
    BillingPageviewsQuery = "BillingPageviewsQuery",
    BillingEmailQuery = "BillingEmailQuery",
    BillingExtraPageviewsMutation = "BillingExtraPageviewsMutation",
    UpcomingInvoiceQuery = "UpcomingInvoiceQuery",
    GoalQuery = "GoalQuery",
    GoalsBasicsQuery = "GoalsBasicsQuery",
    WebsitePushSettingsQuery = "WebsitePushSettingsQuery",
    CreateGoalMutation = "CreateGoalMutation",
    UpdateGoalMutation = "UpdateGoalMutation",
    DeleteGoalMutation = "DeleteGoalMutation",
    PlansQuery = "PlansQuery",
    SubscribeMutation = "SubscribeMutation",
    UpdateBillingEmailMutation = "UpdateBillingEmailQuery",
    UnsubscribeMutation = "UnsubscribeMutation",
    LoginGoogleMutation = "LoginGoogleMutation",
    LoginMutation = "LoginMutation",
    DeleteUsersMutation = "DeleteUsersMutation",
    UpdateRoleMutation = "UpdateRoleMutation",
    InviteUserMutation = "InviteUserMutation",
    ResendInviteMutation = "ResendInviteMutation",
    UpdateWebsiteMutation = "UpdateWebsiteMutation",
    UpdateWebsitePushSettingsMutation = "UpdateWebsitePushSettingsMutation",
    CreateWebsiteMutation = "CreateWebsiteMutation",
    DeleteWebsiteMutation = "DeleteWebsiteMutation",
    ResendEmailMutation = "ResendEmailMutation",
    UpdateAccountNameMutation = "UpdateAccountNameMutation",
    DeleteAccountPictureMutation = "DeleteAccountPictureMutation",
    UpdateAccountPictureMutation = "UpdateAccountPictureMutation",
    CheckLoginMutation = "CheckLoginMutation",
    SendInstructionEmailMutation = "SendInstructionEmailMutation",
    SendBellRequestMutation = "SendBellRequestMutation",
    UpdateGoalsSettingsMutation = "UpdateGoalsSettingsMutation",
    SendCancellationRequestMutation = "SendCancellationRequestMutation",
}

/** In case request fails the response type will be RequestError. It contains a key `code` with the request HTTP code value. Additional keys for validation can be added. eg: RequestError<{ email: string[] }> */
export type RequestError<T = Record<string, never>> = {
    code: number;
    message?: string;
    validation: Partial<T>;
};

/** We either mock the API when VITE_API_MOCK_ENABLED is set to "true" or when running code through vitest package.*/
const isMocking =
    import.meta.env.VITE_API_MOCK_ENABLED === "true" ||
    import.meta.env.VITEST === "true";

/** If mocking is enabled, url will target worker service. */
export const graphQLUrl = isMocking
    ? import.meta.env.VITE_API_MOCK_URL
    : "/graphql";

/** To prevent CORS policy issues, we need to omit credentials when mocking requests. */
const credentials: RequestCredentials = isMocking ? "omit" : "include";

export const graphQLClient = new GraphQLClient(graphQLUrl, {
    credentials,
    responseMiddleware: (
        data:
            | {
                  status: number;
                  response?: never;
              }
            | (Error & {
                  response?: {
                      status?: number;
                      message?: string;
                      errors?: GraphQLError[];
                  };
              }),
    ) => {
        if (data.response) {
            const errors = data.response.errors ?? [];

            /** Formatting error state according custom wisepops backend format. */
            const validation = errors.reduce<Record<string, string[]>>(
                (obj, { extensions }) => {
                    return {
                        ...obj,
                        ...(((extensions as GraphQLErrorExtensions | undefined)
                            ?.validation_errors ?? {}) as
                            | Record<string, string[]>
                            | undefined),
                    };
                },
                {},
            );

            const obj: RequestError<Record<string, string[]>> = {
                code: data.response.status ?? 500,
                message:
                    data.response.message || errors[0]?.message || undefined,
                validation,
            };

            throw obj;
        }
    },
    requestMiddleware: (
        request: RequestInit & {
            url: string;
            operationName?: string;
            variables?: Variables;
        },
    ) => ({
        ...request,
        url: `${request.url}?op=${request.operationName || "unknown"}`,
    }),
});
