import {
    type HTMLAttributes,
    type ReactNode,
    type ComponentPropsWithRef,
    type MouseEventHandler,
    type JSX,
    useContext,
    useCallback,
    useEffect,
    forwardRef,
    useState,
    createContext,
    useMemo,
    useRef,
} from "react";
import { createPortal } from "react-dom";
import { type TransitionStatus, Transition } from "react-transition-group";
import styled, {
    type FlattenSimpleInterpolation,
    css,
    keyframes,
} from "styled-components";

import { useInternalRef } from "@/Support/Hooks/useInternalRef";

import TimesIcon from "@/Components/Icons/TimesIcon.svg?react";
import { Text } from "@/Components/Text";

export type ToastKind = "default" | "error" | "success";

const Container = styled(Text.Body)<{
    $kind: ToastKind;
}>`
    align-items: center;
    border-radius: 4px;
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    gap: 20px;
    padding: 12px;
    width: fit-content;
    ${({ $kind }): FlattenSimpleInterpolation | false =>
        $kind === "default" &&
        css`
            background-color: var(--color-neutral-900);
        `}
    ${({ $kind }): FlattenSimpleInterpolation | false =>
        $kind === "error" &&
        css`
            background-color: var(--color-destructive-600);
        `}

    ${({ $kind }): FlattenSimpleInterpolation | false =>
        $kind === "success" &&
        css`
            background-color: var(--color-success-500);
        `}
`;

type ToastProps = HTMLAttributes<HTMLDivElement> & {
    readonly kind: ToastKind;
    readonly timer?: number;
    readonly onClose: MouseEventHandler<HTMLDivElement>;
};

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
    (
        { children = null, kind = "default", onClose, timer, ...otherProps },
        ref,
    ): JSX.Element => {
        const internalRef = useInternalRef<HTMLDivElement>(ref);

        const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
            (event) => {
                event.stopPropagation();
                onClose(event);
            },
            [onClose],
        );

        useEffect(
            () => {
                if (typeof timer === "number") {
                    setTimeout(() => {
                        if (internalRef.current) {
                            internalRef.current.click();
                        }
                    }, timer);
                }
            },
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
            [],
        );

        return (
            <Container
                {...otherProps}
                $kind={kind}
                onClick={onClick}
                ref={internalRef}
            >
                {children}
                <TimesIcon height="16" width="16" />
            </Container>
        );
    },
);

Toast.displayName = "Toast";

const slideIn = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
`;

const AnimatedToast = styled(Toast)<{
    $state: TransitionStatus;
}>`
    ${({ $state }): FlattenSimpleInterpolation | false =>
        $state === "entering" &&
        css`
            animation: ${slideIn} 0.2s cubic-bezier(0.5, 1, 0.89, 1) forwards;
        `}
    ${({ $state }): FlattenSimpleInterpolation | false =>
        $state === "exiting" &&
        css`
            animation: ${slideIn} 0.2s cubic-bezier(0.5, 1, 0.89, 1) forwards
                reverse;
        `}
`;

type ToastItem = {
    kind: ToastKind;
    content: string;
    timer?: number;
};

const ToastContext = createContext<{
    toast: ToastItem | null;
    createToast: (kind: ToastKind, content: string, timer?: number) => void;
    deleteToast: () => void;
}>({
    toast: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    createToast: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    deleteToast: () => {},
});

const StyledToasts = styled.div`
    align-content: center;
    background: transparent;
    bottom: 10px;
    display: flex;
    flex-flow: column-reverse wrap;
    gap: 6px;
    left: 10px;
    max-height: calc(100% - 20px);
    pointer-events: none;
    position: fixed;
    right: 10px;
    z-index: var(--z-index-toasts);

    & * {
        pointer-events: initial;
    }
`;

const ControlledToast = ({
    onClose,
    ...otherProps
}: Omit<ComponentPropsWithRef<typeof Toast>, "onClose"> & {
    readonly onClose: () => void;
}): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    const [shown, setShown] = useState(true);

    const handleLocalClose = useCallback(() => {
        setShown(false);
    }, []);

    return (
        <Transition
            appear
            in={shown}
            nodeRef={ref}
            onExited={onClose}
            timeout={200}
        >
            {(state): JSX.Element => (
                <AnimatedToast
                    {...otherProps}
                    $state={state}
                    onClose={handleLocalClose}
                    ref={ref}
                />
            )}
        </Transition>
    );
};

const Toasts = ({
    parent,
}: {
    readonly parent: HTMLElement;
}): JSX.Element | null => {
    const { toast, deleteToast } = useContext(ToastContext);

    if (toast === null) {
        return null;
    }
    return createPortal(
        <StyledToasts>
            <ControlledToast
                kind={toast.kind}
                onClose={deleteToast}
                timer={toast.timer}
            >
                {toast.content}
            </ControlledToast>
        </StyledToasts>,
        parent,
    );
};

export const ToastProvider = ({
    children,
    parent,
}: {
    readonly children: ReactNode;
    readonly parent: HTMLElement;
}): JSX.Element => {
    const [toast, setToast] = useState<ToastItem | null>(null);

    const deleteToast = useCallback(() => {
        setToast(null);
    }, []);

    const createToast = useCallback(
        (kind: ToastKind, content: string, timer?: number) => {
            setToast({ kind, content, timer: timer ?? 6000 });
        },
        [],
    );

    const value = useMemo(
        () => ({ toast, createToast, deleteToast }),
        [toast, createToast, deleteToast],
    );

    return (
        <ToastContext.Provider value={value}>
            <Toasts parent={parent} />
            {children}
        </ToastContext.Provider>
    );
};

export const useToasts = (): {
    createToast: (kind: ToastKind, content: string, timer?: number) => void;
    deleteToast: () => void;
} => {
    const { createToast, deleteToast } = useContext(ToastContext);

    return { createToast, deleteToast };
};
