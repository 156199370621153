import { useCallback } from "react";
import {
    type NavigateOptions,
    type Path,
    type To,
    useLocation,
    useNavigate as useBasicNavigate,
    useSearchParams,
} from "react-router-dom";

/** Query params that are not inherited from the current URL when navigating. currentWebsite has its own logic */
const skippedParams = new Set([
    "user",
    "toast",
    "shop",
    "shopifyApp",
    "currentWebsite",
]);

type CustomURLFunction = (to: To) => Path;

/** Hook returning a function that return an URL completed with the query params we want to keep. This behavior has been implemented to keep Users and Websites tables filters when navigating to their sub routes. */
export const useCustomURL = (): CustomURLFunction => {
    const [currentQueryParams] = useSearchParams();

    const location = useLocation();

    return useCallback<CustomURLFunction>(
        (to) => {
            // Morph "String" to "To" type
            const ret: Path =
                typeof to === "string"
                    ? {
                          pathname: to.split("?")[0] ?? "/",
                          search: to.split("?")[1] ?? "?",
                          hash: (to.split("#")[1] ?? "").split("?")[0] ?? "#",
                      }
                    : {
                          pathname: to.pathname ?? "/",
                          search: to.search ?? "?",
                          hash: to.hash ?? "#",
                      };

            // Keeps resource related search params. Uses "skippedParams" variable.
            if (
                (location.pathname.includes("/settings/websites") &&
                    ret.pathname.includes("/settings/websites")) ||
                (location.pathname.includes("/settings/users") &&
                    ret.pathname.includes("/settings/users")) ||
                (location.pathname.includes("/websites/select") &&
                    ret.pathname.includes("/websites/select")) ||
                ret.pathname.includes("/account/setup")
            ) {
                const futurQueryParams = new URLSearchParams(ret.search);

                // Sets back previous query params that are not skipped and not redefined in futur URL
                currentQueryParams.forEach((value, key) => {
                    if (
                        futurQueryParams.get(key) === null &&
                        !skippedParams.has(key)
                    ) {
                        futurQueryParams.set(key, value);
                    }
                });

                ret.search = futurQueryParams.toString();
            }

            const futurQueryParams = new URLSearchParams(ret.search);

            const currentWebsiteId = currentQueryParams.get("currentWebsite");

            // Keeps currentWebsite query param in URL when navigating if not redefined in futur URL
            if (!futurQueryParams.get("currentWebsite") && currentWebsiteId) {
                futurQueryParams.set("currentWebsite", currentWebsiteId);

                ret.search = futurQueryParams.toString();
            }

            return ret;
        },
        [location.pathname, currentQueryParams],
    );
};

type NavigateFunction = (to: To, options?: NavigateOptions) => void;

/** Overrides navigate function from react router while keeping the query params we want to keep. */
export const useNavigate = (): NavigateFunction => {
    const navigate = useBasicNavigate();

    const getTo = useCustomURL();

    return useCallback<NavigateFunction>(
        (to, options) => {
            navigate(getTo(to), options);
        },
        [navigate, getTo],
    );
};
