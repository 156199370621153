import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";

import Root from "./Root";
import "./style.css";

const enableMocking = async () => {
    if (
        import.meta.env.VITE_API_MOCK_ENABLED !== "true" &&
        import.meta.env.VITEST !== "true"
    ) {
        return;
    }

    const { worker } = await import("./Support/Requests/Mocks/browser");

    return worker.start({
        onUnhandledRequest: "bypass",
    });
};

if (import.meta.env.VITE_SENTRY_ENABLED === "true") {
    if (!import.meta.env.VITE_SENTRY_DSN) {
        throw new Error(
            `Sentry reporting is enabled but VITE_SENTRY_DSN is not defined`,
        );
    }
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        tracesSampleRate: 0.5,
        environment: import.meta.env.MODE,
    });
}

const container = document.getElementById("app");

if (container === null) {
    throw new Error(
        `HTML element with id "app" does not exist and therefore React app cannot be rendered.`,
    );
}

const root = createRoot(container);

void enableMocking().then(() => {
    root.render(<Root />);
});
