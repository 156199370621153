import { type ReactNode, type JSX } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useMe } from "@/Support/Requests/useMe";

export const ProtectedRoute = ({
    children,
}: {
    readonly children: ReactNode;
}): JSX.Element => {
    const query = useMe();

    const location = useLocation();

    if (!query.isSuccess) {
        return (
            <Navigate
                to={{
                    pathname: "/login",
                    search:
                        location.pathname !== "/" || location.search
                            ? `redirect_domain=w2&redirect_path=${encodeURIComponent(
                                  `${location.pathname}${location.search}`,
                              )}`
                            : undefined,
                }}
            />
        );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};
