import {
    type ErrorInfo,
    type ReactNode,
    type ComponentPropsWithoutRef,
    type JSX,
    PureComponent,
} from "react";
import { type IntlShape, injectIntl } from "react-intl";
import { type Location, useLocation } from "react-router-dom";

import { Button } from "@/Components/Button";

import {
    Container,
    SubTitle,
    Description,
    Actions,
    BackToHome,
} from "./Components/ErrorComponents";

type ScriptErrorBoundaryState = { hasError: boolean };

type ScriptErrorBoundaryProps = {
    readonly children: ReactNode;
    readonly location: Location;
    readonly intl: IntlShape;
};

class ScriptErrorBoundaryClass extends PureComponent<
    ScriptErrorBoundaryProps,
    ScriptErrorBoundaryState
> {
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility, react/sort-comp
    constructor(props: ScriptErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
        this.handleRetry = this.handleRetry.bind(this);
    }

    public static getDerivedStateFromError(): ScriptErrorBoundaryState {
        return { hasError: true };
    }

    public static componentDidCatch(error: Error, info: ErrorInfo): void {
        console.error("Uncaught error:", error, info);
    }

    public componentDidUpdate(prevProps: ScriptErrorBoundaryProps): void {
        const { location } = this.props;
        const { pathname } = location;

        if (prevProps.location.pathname !== pathname) {
            this.setState((prev) => ({ ...prev, hasError: false }));
        }
    }

    public handleRetry(): void {
        this.setState({ hasError: false });
    }

    public render(): JSX.Element {
        const { hasError } = this.state;
        const { children, intl, location } = this.props;
        const { pathname } = location;

        if (hasError) {
            return (
                <Container>
                    <SubTitle>
                        {intl.formatMessage({
                            id: "error.scriptError.title",
                            defaultMessage: "Something went wrong",
                            description: "Script error page title",
                        })}
                    </SubTitle>
                    <Description>
                        {intl.formatMessage({
                            id: "error.scriptError.message",
                            defaultMessage:
                                "An error occurred in the page, please try again later or contact our support using the chat at the bottom right of your screen.",
                            description: "Script error page message",
                        })}
                    </Description>
                    <Actions>
                        <Button
                            kind="primary"
                            onClick={this.handleRetry}
                            size="small"
                        >
                            {intl.formatMessage({
                                id: "error.scriptError.retry",
                                description:
                                    "Error boundary try again button text",
                                defaultMessage: "Try again",
                            })}
                        </Button>
                        {pathname !== "/account" && <BackToHome kind="ghost" />}
                    </Actions>
                </Container>
            );
        }

        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }
}

const ScriptErrorBoundaryComponent = (
    props: Omit<
        ComponentPropsWithoutRef<typeof ScriptErrorBoundaryClass>,
        "location"
    >,
): JSX.Element => {
    const location = useLocation();

    return <ScriptErrorBoundaryClass location={location} {...props} />;
};

const ScriptErrorBoundary = injectIntl<
    "intl",
    Omit<ComponentPropsWithoutRef<typeof ScriptErrorBoundaryClass>, "location">
>(ScriptErrorBoundaryComponent);

export default ScriptErrorBoundary;
