import { useCallback, useMemo } from "react";
import { type IntlShape, useIntl } from "react-intl";

// TODO: rework to descriptors dictionary when intl extract supports non-inlined descriptors
const getStatusMessages = (
    intl: IntlShape,
): Record<number, string | undefined> => ({
    400: intl.formatMessage({
        id: "request.code.400",
        description: "Error message for HTTP 400",
        defaultMessage: "The server cannot process the request.",
    }),
    401: intl.formatMessage({
        id: "request.code.401",
        description: "Error message for HTTP 401",
        defaultMessage: "Unauthorized.",
    }),
    403: intl.formatMessage({
        id: "request.code.403",
        description: "Error message for HTTP 403",
        defaultMessage: "Forbidden.",
    }),
    404: intl.formatMessage({
        id: "request.code.404",
        description: "Error message for HTTP 404",
        defaultMessage: "The requested resource could not be found.",
    }),
    500: intl.formatMessage({
        id: "request.code.405",
        description: "Error message for HTTP 405",
        defaultMessage: "Server error.",
    }),
    517: intl.formatMessage({
        id: "request.code.517",
        description: "Error message for HTTP 517",
        defaultMessage: "The server is in maintenance.",
    }),
});

export const useRequestCodeText = (): ((code: number) => string | null) => {
    const intl = useIntl();

    const messages = useMemo(() => getStatusMessages(intl), [intl]);

    return useCallback(
        (code: number): string | null => messages[code] ?? null,
        [messages],
    );
};
