import { type JSX } from "react";
import {
    Navigate as BasicNavigate,
    generatePath,
    useParams,
} from "react-router-dom";

import { useCustomURL } from "@/Support/Hooks/useNavigate";

export const Navigate = ({
    to,
    ...props
}: Parameters<typeof BasicNavigate>[0]): JSX.Element => {
    const getCustomUrl = useCustomURL();

    const customTo = getCustomUrl(to);

    const params = useParams();

    const toWithParams = generatePath(customTo.pathname, params);

    return <BasicNavigate {...props} to={toWithParams} />;
};
