import { graphql } from "@/__codegen__";

import { type UseQueryOptions, useQuery } from "@/Support/Hooks/useQuery";

import {
    type BillingEditorPageviewsQuery,
    type BillingEditorPageviewsQueryVariables,
    namedOperations,
} from "@/__codegen__/graphql";

import { type RequestError, graphQLClient } from "./graphQLClient";
import { ReactQueryKeys } from "./reactQueryKeys";

export type EditorBillingPageviewsError = RequestError;

/** Gets pageviews billing information from a workspace for EDITOR as they don't have access to whole billing data as OWNER or ADMIN do */
export const useEditorBillingPageviews = (
    variables: BillingEditorPageviewsQueryVariables,
    options?: UseQueryOptions<
        BillingEditorPageviewsQuery["pageviewInformation"],
        EditorBillingPageviewsError
    >,
) =>
    useQuery({
        queryKey: [
            ReactQueryKeys.BILLING,
            namedOperations.Query.BillingEditorPageviews,
            variables,
        ],
        queryFn: async ({ signal }) => {
            const { pageviewInformation } = await graphQLClient.request({
                document: graphql(`
                    query BillingEditorPageviews($workspaceId: Int!) {
                        pageviewInformation(workspaceId: $workspaceId) {
                            current_pageviews
                            plan_pageviews
                            expires_at
                            is_expired
                            is_trial
                            trial_duration
                            extra_pageviews_enabled
                            expires_at
                        }
                    }
                `),
                variables,
                signal,
            });

            return pageviewInformation;
        },
        ...options,
    });
