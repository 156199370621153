import { type Dispatch, type SetStateAction, createContext } from "react";

export interface AppContext {
    maintenanceMode: boolean;
    setMaintenanceMode: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<AppContext>({} as AppContext);

export default Context;
