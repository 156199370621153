/* eslint-disable react/require-optimization */

import { type JSX, Component } from "react";
import styled from "styled-components";

import { type Props as StaticWheelProps, StaticWheel } from "./StaticWheel";

interface State {
    deg?: number;
}

/*
 * The wheel will rotate between 5 and 10 full rotation
 */
const ROTATION = Math.floor(Math.random() * 5) + 5;
const ANIMATION_TIME = 300 + ROTATION * 700;
const OFFSET = Math.random() * 20 - 10;

const Wheel = styled(StaticWheel)<{ deg?: number }>`
    transform: rotate(
        ${({ deg }): number =>
            deg === undefined ? 0 : deg + 360 * ROTATION}deg
    );
    transform-origin: center;
    transition: ${ANIMATION_TIME}ms transform ease-out;
`;

type SpinWheelProps = StaticWheelProps & {
    readonly deg?: number;
    readonly resolveWaitTime?: number;
};

export class SpinWheel extends Component<SpinWheelProps, State> {
    public constructor(props: SpinWheelProps) {
        super(props);
        this.state = { deg: props.deg ?? undefined };
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    public spinTo = async (index: number): Promise<void> =>
        new Promise<void>((resolve) => {
            const { resolveWaitTime } = this.props;

            // process angle for picked index
            const deg = (360 * -index) / 12 - 360 / 24 - 90;

            this.setState({
                deg: deg - OFFSET,
            });

            // Resolve after wheel animation + 3 seconds
            setTimeout(resolve, ANIMATION_TIME + (resolveWaitTime ?? 3000));
        });

    public render(): JSX.Element {
        const { deg } = this.state;

        return <Wheel deg={deg} {...this.props} />;
    }
}
