import { type ReactNode, type JSX } from "react";

import { useCurrentWorkspace } from "@/Support/Hooks/useCurrentWorkspace";

import { Navigate } from "@/Components/Navigate";

export const RegisteredRoute = ({
    children,
}: {
    readonly children: ReactNode;
}): JSX.Element => {
    const currentWorkspace = useCurrentWorkspace();

    if (!currentWorkspace?.is_setup) {
        return <Navigate to="/account/setup" />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};
