import { type HTMLAttributes, type JSX, useEffect } from "react";
import styled from "styled-components";

import { useQueryParamsToast } from "@/Support/Hooks/useQueryParamsToast";
import { useSegment } from "@/Support/Hooks/useSegment";

import { useToasts } from "@/Components/Toast";

const usePageToast = (): void => {
    const { createToast } = useToasts();

    const toast = useQueryParamsToast();

    useEffect(() => {
        if (toast !== undefined) {
            createToast(toast.level, toast.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toast]);
};

const Page = styled(
    ({
        title = "Wisepops",
        ...otherProps
    }: HTMLAttributes<HTMLDivElement> & {
        title: string;
    }): JSX.Element => {
        const segment = useSegment();

        useEffect(() => {
            document.title = title;
            segment.page(title);
            window.wisepops?.("pageview");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [title]);

        usePageToast();

        return <div {...otherProps} />;
    },
)`
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow: auto;
`;

Page.displayName = "PageWrapper";

export default Page;
