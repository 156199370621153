/* eslint-disable react/jsx-no-literals */

import {
    ConsentManager,
    loadPreferences,
    savePreferences,
} from "@segment/consent-manager";
import inEU from "@segment/in-eu";
import { useEffect, type JSX } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const Container = styled.div`
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: var(--z-index-consent);

    & > div > div {
        padding-bottom: 12px;
        padding-top: 12px;
    }
`;

const setGlobalConsentManager = (): void => {
    if (!window.consentManager?.preferences) {
        window.consentManager = {
            preferences: {
                loadPreferences,
                savePreferences,
            },
        };
    }
};

export const Consent = (): JSX.Element => {
    const intl = useIntl();

    useEffect(() => {
        setGlobalConsentManager();
    }, []);

    return (
        <Container id="cookie-consent">
            <ConsentManager
                bannerActionsBlock
                bannerBackgroundColor="#1B2733"
                bannerContent={
                    <span>
                        {intl.formatMessage({
                            id: "consent.content",
                            defaultMessage:
                                'We use cookies to enhance your browsing experience, serve personalized ads, and analyze our traffic. By clicking "Allow all", you consent to our use of cookies as described in our',
                            description: "Consent banner - content",
                        })}{" "}
                        <a
                            href="https://wisepops.com/privacy-policy"
                            rel="noreferrer"
                            target="_blank"
                        >
                            {intl.formatMessage({
                                id: "consent.content.link",
                                defaultMessage: "Privacy Policy",
                                description: "Consent banner - link content",
                            })}
                        </a>
                        .
                    </span>
                }
                bannerHideCloseButton
                bannerSubContent={intl.formatMessage({
                    id: "consent.sub.content",
                    defaultMessage:
                        "You can change your preferences at any time.",
                    description: "Consent banner - sub content",
                })}
                cancelDialogContent={intl.formatMessage({
                    id: "consent.cancel.dialog.content",
                    defaultMessage:
                        "Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Privacy Policy.",
                    description: "Consent banner - cancel dialog content",
                })}
                cancelDialogTitle={intl.formatMessage({
                    id: "consent.cancel.dialog.title",
                    defaultMessage: "Are you sure you want to cancel?",
                    description: "Consent banner - cancel dialog title",
                })}
                preferencesDialogContent={intl.formatMessage({
                    id: "consent.preferences.dialog.content",
                    defaultMessage:
                        "We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.",
                    description: "Consent banner - preferences dialog content",
                })}
                preferencesDialogTitle={intl.formatMessage({
                    id: "consent.preferences.dialog.title",
                    defaultMessage: "Data Collection Preferences",
                    description: "Consent banner - preferences dialog title",
                })}
                shouldRequireConsent={inEU}
                writeKey={import.meta.env.VITE_SEGMENT_WRITE_KEY}
            />
        </Container>
    );
};
