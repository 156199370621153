import { type MeResponse, useMe } from "../Requests/useMe";

/** Returns current workspace using useMe hook. As we don't handle multiple workspaces for now, it uses the first of the list. */
export const useCurrentWorkspace = ():
    | MeResponse["workspaces"]["items"][0]
    | null => {
    const { data } = useMe();

    return data?.workspaces.items[0] ?? null; // TODO: later improve when handling multiple workspaces
};
